import React, { Component } from "react"

import styled from "styled-components"

const kanbans = require("../data/kanbans.json")
const environments = require("../data/environments.json")

const Container = styled.nav`
  background: ${p => p.theme.color.primaryBackground};
  padding: 1.5rem;
  box-shadow: ${p => p.theme.shadow.small};
`

const Content = styled.div`
  display: flex;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
`

const Link = styled.a`
  ${p => p.theme.textStyle.xs}
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${p => p.theme.color.text01};

  ${p => p.active && `font-weight: 600`}
`

const LogoSQY = styled.img`
  padding-right: 1.5rem;
  border-right: 1px solid ${p => p.theme.color.ui03};
`

const LogoIE = styled.img`
  display: none;

  ${p => `${p.theme.mq.m} {
      & {
          display: block;
      }
  }`}
`

const ResponsiveMenu = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  ${p => `${p.theme.mq.m} {
      & {
          justify-content: flex-start;
        }
  }`}
`

const LinksGroup = styled.div`
  display: ${p => (p.visible ? "block" : "none")};
  position: absolute;
  top: calc(100% + 1.5rem);
  z-index: 99;
  background: ${p => p.theme.color.primaryBackground};
  box-shadow: ${p => p.theme.shadow.small};
  padding: 1.5rem;

  & > a,
  & > * > a {
    margin-left: 0;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid white;
    transition: all 0.2s ease;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      border-bottom: 1px solid ${p => p.theme.color.text01};
    }
  }

  ${p => `${p.theme.mq.m} {
      & {
          display: flex;
          flex-direction: row;
          margin-right: 1.5rem;
          position: relative;
          box-shadow: none;
          padding: 0;

          a {
              margin-bottom: 0;
              margin-left: 1.5rem;
          }
        }
  }`}
`

const MenuIcon = styled.a`
  display: block;
  height: 2rem;
  width: 2rem;
  background: url(${require("../img/menu.svg")});
  cursor: pointer;

  ${p => `${p.theme.mq.m} {
      & {display: none}
  }`};
`

const DropdownGroup = styled.div`
  :hover > div {
    display: block;
  }
`

const DropdownElements = styled.div`
  background: white;
  padding-top: 0.5rem;

  ${p => `${p.theme.mq.m} {
      & {
        display: none;
        position: absolute;
        padding-top: 0;
        margin-left: 1rem;
        width: fit-content;
      }
  }`}

  :hover {
    display: block;
  }
`

const ShadowBox = styled.div`
  ${p => `${p.theme.mq.m} {
      & {
        margin-top: 1.5rem;
        box-shadow: ${p.theme.shadow.small};
      }
  }`}
`

const DropdownLink = styled.a`
  ${p => p.theme.textStyle.xxs}
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${p => p.theme.color.text01};
  margin-left: 0 !important;
  padding: 0.75rem 1.75rem;

  ${p => p.active && `font-weight: 600`}

  &:hover {
    border-bottom: none !important;
    background: ${p => p.theme.color.interactive01Hover};
  }
`

export default class Nav extends Component {
  constructor(props) {
    super(props)

    this.state = { showMenu: false }
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu })
  }

  render() {
    return (
      <Container>
        <Content>
          <Link href="/">
            <LogoSQY src={require("../img/logo_image.svg")} />
          </Link>

          <ResponsiveMenu>
            <MenuIcon onClick={this.toggleMenu} />
            <LinksGroup visible={this.state.showMenu}>
              <Link href="/" active>
                Dashboard
              </Link>
              <DropdownGroup>
                <Link href="javascript:;">Proyectos Taiga</Link>
                <DropdownElements>
                  <ShadowBox>
                    {kanbans.kanbans.map(kanban => (
                      <DropdownLink href={kanban.url} target="_blank">
                        {kanban.name}
                      </DropdownLink>
                    ))}
                  </ShadowBox>
                </DropdownElements>
              </DropdownGroup>
              <Link href="http://iewiki.secuoyas.io/" target="_blank">
                Wiki
              </Link>
              <DropdownGroup>
                <Link href="javascript:;">Entornos</Link>
                <DropdownElements>
                  <ShadowBox>
                    {environments.environments.map(
                      env =>
                        env.status === "ready" && (
                          <DropdownLink href={env.url} target="_blank">
                            {env.name}
                          </DropdownLink>
                        )
                    )}
                  </ShadowBox>
                </DropdownElements>
              </DropdownGroup>
            </LinksGroup>
          </ResponsiveMenu>

          <Link href="/">
            <LogoIE src={require("../img/client_ie_105x40px.svg")} />
          </Link>
        </Content>
      </Container>
    )
  }
}
