import React, { Component } from "react"

import styled from "styled-components"

const Container = styled.div`
  background: ${p => p.theme.color.ui02};
  display: flex;
  align-items: center;
  ${p => p.theme.textStyle.tiny};
  padding: 0.5rem 0;
  box-shadow: ${p => p.theme.shadow.small};
  position: relative;
  z-index: 9;

  &:before {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    top: calc(50% - 0.5rem);
    left: -0.25rem;
    margin: 0 auto;
    background: ${p => p.theme.color.ui02};
  }

  &:not(:last-child):after {
    content: "";
    width: 1rem;
    height: 0.5rem;
    position: absolute;
    top: calc(50% - 0.5rem);
    right: -1rem;
    margin: 0 auto;
    box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.14);
    background: ${p => p.theme.color.ui02};
  }

  &:first-child:before {
    display: none;
  }
`

const Icon = styled.img`
  height: 2rem;
`

const Title = styled.p`
  text-transform: uppercase;
  align-self: end;
`

const Label = styled.p`
  align-self: start;
  color: ${p => p.theme.color.text03};
`

const Data = styled.p`
  ${p => p.theme.textStyle.l};
  color: ${p => p.theme.color.text02};
  margin-right: 1rem;

  ${p => p.alert === "red" && `color: #E53935;`}
  ${p => p.alert === "orange" && `color: #FF9800;`}
`

const Row = styled.div`
  padding: 0.5rem 1rem;

  ${({ borderRight, theme }) => borderRight && `border-right: 1px solid ${theme.color.ui03};`}
`

const RowGrid = styled(Row)`
  display: grid;
  grid-template-areas:
    "data title"
    "data label";
  grid-template-rows: 1fr 1fr;
  align-items: center;

  & > p:first-child {
    grid-area: data;
  }
`

export default class Card extends Component {
  render() {
    return (
      <Container alert={this.props.alert}>
        <Row borderRight>
          <Icon src={this.props.img} />
        </Row>

        <RowGrid>
          <Data alert={this.props.alert}>{this.props.data}</Data>
          <Title>{this.props.title}</Title>
          <Label>tasks</Label>
        </RowGrid>
      </Container>
    )
  }
}
