import React, { Component } from "react"

import styled, { keyframes, css } from "styled-components"

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const animation = () =>
  css`
    ${rotate} 2s infinite alternate;
  `

const Container = styled.div`
  align-self: flex-end;
  margin-bottom: 0.2rem;
`

const Button = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;

  color: ${p => p.theme.color.text01};
  padding: 0 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  height: 100%;
  transition: all 0.2s ease;

  &:hover {
    background: ${p => p.theme.color.ui02};
  }
`

const Icon = styled.img`
  height: 1.5rem;
  width: auto;
  animation: ${animation};
  ${p => !p.active && `animation: none`};
`

export default class RefreshData extends Component {
  render() {
    return (
      <Container>
        <Button onClick={this.props.reloadData} active={this.props.loading}>
          <Icon src={require("../img/reload.svg")} active={this.props.loading} />
        </Button>
      </Container>
    )
  }
}
