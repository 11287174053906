import React, { Component } from "react"

import styled from "styled-components"

import Card from "./HorizontalCard"

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  justify-content: center;
  grid-gap: 1rem;
  padding: 0.03rem;
  overflow: hidden;

  @media (min-width: ${p => p.theme.breakpoints[1]}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: ${p => p.theme.breakpoints[3]}) {
    grid-template-columns: repeat(5, 1fr);
  }
`

export default class GeneralData extends Component {
  render() {
    const througput = this.props.kpis.througput

    let qaAlert

    if (this.props.data.quality >= througput / 2) {
      qaAlert = "orange"
    } else if (this.props.data.quality >= througput) {
      qaAlert = "red"
    }

    let pushAlert = this.props.data.ready >= througput / 5 ? "red" : undefined

    return (
      <Container>
        <Card title="Backlog" data={this.props.data.backlog} img={require("../img/layers.svg")} />
        <Card title="Develop" data={this.props.data.develop} img={require("../img/settings.svg")} />
        <Card title="Quality" data={this.props.data.quality} img={require("../img/like.svg")} alert={qaAlert} />
        <Card title="Ready" data={this.props.data.ready} img={require("../img/success.svg")} alert={pushAlert} />
        <Card title="Live" data={this.props.data.live} img={require("../img/flag.svg")} />
      </Container>
    )
  }
}
