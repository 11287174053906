import React, { Component } from "react"

import styled from "styled-components"

const Container = styled.div`
  background: ${p => p.theme.color.ui02};
  text-align: center;
  ${p => p.theme.textStyle.tiny};
  padding: 1rem;
  margin-bottom: 1rem;
  min-width: 10rem;

  ${p => `${p.theme.mq.m} {
    :last-child {
      margin-bottom: 0;
    }
  `}
`

const Title = styled.p`
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${p => p.theme.color.ui03};
`

const Data = styled.p`
  ${p => p.theme.textStyle.m};
  padding: 0.5rem;
`

const Label = styled.p`
  color: ${p => p.theme.color.text03};
`

export default class VerticalCard extends Component {
  render() {
    return (
      <Container>
        <Title>{this.props.title}</Title>
        <Data>{this.props.data}</Data>
        <Label>{this.props.label}</Label>
      </Container>
    )
  }
}
