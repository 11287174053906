import _theme from "./theme"
import custom from "./custom"
import { parseTheme } from "@secuoyas/magma-theme"

const theme = {
  ...parseTheme(_theme),
  ...custom
}

export default theme
