import React, { Component } from "react"

import styled from "styled-components"

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december"
]

const availableDates = [{ 2018: months }, { 2019: months }]

const Container = styled.div``

const Group = styled.div`
  margin-bottom: 1rem;
`

const Title = styled.p`
  margin-bottom: 0.5rem;
`

const Options = styled.div`
  display: grid;
  grid-template-columns: repeat(${p => p.cols}, 1fr);
`

const Button = styled.button`
  border: none;
  padding: 0.3rem 0.1rem;
  color: ${p => p.theme.color.text01}
  cursor: pointer;
  transition: all .2s ease;
  background: transparent;

  &:hover {
    background: ${p => p.theme.color.interactive01Hover};
  }
`

const Option = styled(Button)`
  text-transform: capitalize;

  ${p =>
    p.active &&
    `
    background: ${p.theme.color.interactive02};
    color: ${p.theme.color.text04};

    &:hover {
      background: ${p.theme.color.interactive02}
    }`}
`

const Confirm = styled(Button)`
  background: ${p => p.theme.color.interactive02};
  color: ${p => p.theme.color.text04};

  &:hover {
    background: ${p => p.theme.color.interactive02Hover};
  }
`

const Cancel = styled(Button)``

export default class Form extends Component {
  componentWillMount() {
    this.setState({ date: { ...this.props.date }, selectedDate: { ...this.props.date } })
  }

  setYear = year => {
    const selectedDate = this.state.selectedDate
    selectedDate.year = year

    this.setState({ selectedDate })
  }

  setMonth = month => {
    const selectedDate = this.state.selectedDate
    selectedDate.month = month

    this.setState({ selectedDate })
  }

  submit = () => {
    this.props.onSubmit({ ...this.state.selectedDate })
  }

  cancel = () => {
    this.setState({ selectedDate: { ...this.state.date } })
    this.props.cancel()
  }

  render() {
    return (
      <Container onClick={this.props.onClick}>
        <Group>
          <Title>Year</Title>
          <Options cols="3">
            {availableDates.map(date => {
              const year = +Object.keys(date)[0]

              return (
                <Option key={year} active={this.state.selectedDate.year === year} onClick={() => this.setYear(year)}>
                  {year}
                </Option>
              )
            })}
          </Options>
        </Group>

        <Group>
          <Title>Month</Title>
          <Options cols="4">
            {months.map((month, index) => {
              return (
                <Option
                  key={month}
                  active={this.state.selectedDate.month === index + 1}
                  onClick={() => this.setMonth(index + 1)}
                >
                  {month.slice(0, 3)}
                </Option>
              )
            })}
          </Options>
        </Group>

        <Options cols="2">
          <Confirm onClick={this.submit}>Apply</Confirm>
          <Cancel onClick={this.cancel}>Cancel</Cancel>
        </Options>
      </Container>
    )
  }
}
