import React, { Component } from "react"

import styled from "styled-components"

import DateForm from "./DateForm"

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december"
]

const Container = styled.div`
  display: flex;
  margin-right: 1rem;
  border-right: 1px solid ${p => p.theme.color.ui03};
`

const DateContainer = styled.div``

const DateInfo = styled.div`
  display: inline;
  position: relative;
`

const DateButton = styled.button`
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${p => p.theme.color.text01};
  padding: 0.5rem;
  transition: all 0.2s ease;

  &:hover {
    background: ${p => p.theme.color.ui02};
  }
`

const Settings = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;

  color: ${p => p.theme.color.text01};
  padding: 0 0.5rem;
  margin-right: 1rem;
  display: flex;
  height: 100%;
  transition: all 0.2s ease;

  &:hover {
    background: ${p => p.theme.color.ui02};
  }
`

const SettingOption = styled.button`
  border: none;
  padding: 0.5rem 1rem;
  text-align: right;
  width: 100%;
  cursor: pointer;
  color: ${p => p.theme.color.text01};
  transition: all 0.2s ease;
  background: transparent;

  &:hover {
    background: ${p => p.theme.color.interactive01Hover};
  }
`

const Span = styled.span``

const Form = styled.div`
  position: absolute;
  background: ${p => p.theme.color.ui02};
  z-index: 99;
  right: 0;
  width: 10rem;
  box-shadow: ${p => p.theme.shadow.small};
  padding: ${p => (p.full ? 0 : `1rem`)};

  ${p => !p.visible && `display: none`}
`

const Icon = styled.img`
  height: 1.5rem;
  width: auto;
`

export default class DateSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showSettings: false,
      showStartDate: false,
      showEndDate: false
    }
    document.addEventListener("click", this.handleOutsideClick)
  }

  componentWillMount() {
    this.setState({ startDate: { ...this.props.date.start }, endDate: { ...this.props.date.end } })
  }

  toggleSettings = () => {
    this.setState({ showSettings: !this.state.showSettings, showStartDate: false, showEndDate: false })
  }

  toggleStartDate = () => {
    this.setState({ showStartDate: !this.state.showStartDate, showSettings: false, showEndDate: false })
  }

  toggleEndDate = () => {
    this.setState({ showEndDate: !this.state.showEndDate, showSettings: false, showStartDate: false })
  }

  handleStartDate = date => {
    this.setState({ showStartDate: false, startDate: date }, () => {
      this.props.changeDate({ start: this.state.startDate, end: this.state.endDate })
    })
  }

  handleEndDate = date => {
    this.setState({ showEndDate: false, endDate: date }, () => {
      this.props.changeDate({ start: this.state.startDate, end: this.state.endDate })
    })
  }

  setLastMonth = () => {
    const date = { year: this.props.date.end.year, month: this.props.date.end.month - 1 }

    if (date.month <= 0) {
      date.month = 12
      date.year = date.year - 1
    }

    this.setState({ startDate: date, endDate: date }, () => {
      this.props.changeDate({ start: this.state.startDate, end: this.state.endDate })
      this.toggleSettings()
    })
  }

  setLastTimester = () => {
    const timesters = [[1, 2, 3, 4], [5, 6, 7, 8], [9, 10, 11, 12]]

    const endDate = { year: this.props.rangeDates.end.year, month: this.props.rangeDates.end.month }
    const startDate = { year: this.props.rangeDates.end.year, month: this.props.rangeDates.end.month }

    timesters.forEach((timester, index) => {
      if (timester.indexOf(endDate.month) !== -1) {
        const actualTimester = index === 0 ? timester : timesters[index - 1]
        startDate.month = actualTimester[0]
        endDate.month = actualTimester[actualTimester.length - 1]
      }
    })

    this.setState({ startDate: startDate, endDate: endDate }, () => {
      this.props.changeDate({ start: this.state.startDate, end: this.state.endDate })
      this.toggleSettings()
    })
  }

  setLastYear = () => {
    const endDate = { year: this.props.rangeDates.end.year, month: this.props.rangeDates.end.month }
    const startDate = { year: this.props.rangeDates.end.year, month: 1 }

    this.setState({ startDate: startDate, endDate: endDate }, () => {
      this.props.changeDate({ start: this.state.startDate, end: this.state.endDate })
      this.toggleSettings()
    })
  }

  setBeginning = () => {
    this.setState({ startDate: this.props.date.start, endDate: this.props.date.end }, () => {
      this.props.changeDate({ start: this.state.startDate, end: this.state.endDate })
      this.toggleSettings()
    })
  }

  setActualTaxYear = () => {
    const endDate = { year: this.props.rangeDates.end.year, month: 9 }
    const startDate = { year: this.props.rangeDates.end.year - 1, month: 9 }

    this.setState({ startDate: startDate, endDate: endDate }, () => {
      this.props.changeDate({ start: this.state.startDate, end: this.state.endDate })
      this.toggleSettings()
    })
  }

  setLastTaxYear = () => {
    const endDate = { year: this.props.rangeDates.end.year - 1, month: 9 }
    const startDate = { year: this.props.rangeDates.end.year - 2, month: 9 }

    if (startDate.year < this.props.rangeDates.start.year) {
      startDate.year = endDate.year
      startDate.month = 1
    }

    this.setState({ startDate: startDate, endDate: endDate }, () => {
      this.props.changeDate({ start: this.state.startDate, end: this.state.endDate })
      this.toggleSettings()
    })
  }

  handleOutsideClick = e => {
    if (this.node.contains(e.target)) {
      return
    }
    this.setState({ showStartDate: false, showEndDate: false })
  }

  render() {
    return (
      <Container ref={node => (this.node = node)}>
        <DateContainer>
          <DateInfo>
            <DateButton onClick={this.toggleStartDate}>
              {months[this.props.rangeDates.start.month - 1]} {this.props.rangeDates.start.year}
            </DateButton>
            {this.state.showStartDate && (
              <Form visible={this.state.showStartDate}>
                <DateForm
                  onSubmit={this.handleStartDate}
                  cancel={this.toggleStartDate}
                  date={this.props.rangeDates.start}
                />
              </Form>
            )}
          </DateInfo>

          <Span> - </Span>

          <DateInfo>
            <DateButton onClick={this.toggleEndDate}>
              {months[this.props.rangeDates.end.month - 1]} {this.props.rangeDates.end.year}
            </DateButton>
            {this.state.showEndDate && (
              <Form visible={this.state.showEndDate}>
                <DateForm onSubmit={this.handleEndDate} cancel={this.toggleEndDate} date={this.props.rangeDates.end} />
              </Form>
            )}
          </DateInfo>
        </DateContainer>

        <DateInfo>
          <Settings onClick={this.toggleSettings}>
            <Icon src={require("../../img/calendar.svg")} />
          </Settings>
          <Form full visible={this.state.showSettings}>
            <SettingOption onClick={this.setLastMonth}>Last month</SettingOption>
            <SettingOption onClick={this.setLastTimester}>Last trimester</SettingOption>
            <SettingOption onClick={this.setLastYear}>Last year</SettingOption>
            <SettingOption onClick={this.setActualTaxYear}>Actual fiscal year</SettingOption>
            <SettingOption onClick={this.setLastTaxYear}>Last fiscal year</SettingOption>
            <SettingOption onClick={this.setBeginning}>From the beginning</SettingOption>
          </Form>
        </DateInfo>
      </Container>
    )
  }
}
