import React, { Component } from "react"
import styled from "styled-components"
import { Line } from "react-chartjs-2"

const Container = styled.article`
  width: 100%;
  padding: 1rem;
  background: white;
  box-sizing: border-box;
  position: relative;
`

const Legend = styled.div`
  position: absolute;
  top: ${p => p.theme.spacing.s};
  right: ${p => p.theme.spacing.s};
  padding: ${p => p.theme.spacing.s};
  background: ${p => p.theme.color.primaryBackground};
`

const Value = styled.div`
  display: flex;
  margin-bottom: 0.5rem;

  :last-child {
    margin-bottom: 0;
  }
`

const Color = styled.span`
  height: 1rem;
  width: 3rem;
  background: ${p => p.color};
  margin-right: 0.3rem;
`

const Label = styled.span`
  font-size: 0.8rem;
`

export default class Graph extends Component {
  setData = data => {
    const cleanData = {
      weekNumbers: [],
      ins: [],
      outs: [],
      ranges: []
    }

    Object.keys(data).forEach(year => {
      Object.keys(data[year]).forEach((week, index) => {
        cleanData.weekNumbers.push(`${week}${index === 0 ? `-${year.slice(2)}` : ``}`)
        cleanData.ins.push(data[year][week].in)
        cleanData.outs.push(data[year][week].out)
      })
    })

    return cleanData
  }

  searchWeek = labelWeek => {
    let result = ""
    for (const year in this.props.data) {
      for (const week in this.props.data[year]) {
        if (this.props.data[year][week].projectWeek === +labelWeek) {
          result = this.props.data[year][week].ranges
        }
      }
    }
    return result
  }

  render() {
    const dataSet = this.setData(this.props.data)

    const data = {
      labels: dataSet.weekNumbers,
      datasets: [
        {
          label: "OUT",
          data: dataSet.outs,
          fill: false, // Don't fill area under the line
          borderColor: "#BEA287", // OUT Line color
          backgroundColor: "#BEA287",
          borderWidth: 2,
          pointRadius: 1,
          lineTension: 0.1
        },
        {
          label: "IN",
          data: dataSet.ins,
          fill: false, // Don't fill area under the line
          borderColor: "#727272", // IN Line color
          backgroundColor: "#727272",
          borderWidth: 2,
          pointRadius: 0.5,
          lineTension: 0.1
        }
      ]
    }

    const options = {
      maintainAspectRatio: false, // Don't maintain w/h ratio
      responsive: true,
      tooltips: {
        mode: "index",
        intersect: false,
        backgroundColor: "white",
        titleFontColor: "#212121",
        titleFontSize: 16,
        titleMarginBottom: 15,
        footerFontSize: 10,
        footerFontColor: "#BBBBBB",
        footerMarginTop: 15,
        footerFontWeight: 100,
        footerTextAlign: "center",
        bodyFontColor: "#616161",
        bodySpacing: 10,
        xPadding: 15,
        yPadding: 15,
        borderWidth: 1,
        borderColor: "rgba(97, 97, 97, 0.3)",
        callbacks: {
          title: tooltipItems => `Week ${tooltipItems[0].label.split("-").join(", year ")}`,
          footer: tooltipItems => this.searchWeek(tooltipItems[0].label.split("-")[0])
        }
      },
      hover: {
        mode: "nearest",
        intersect: true
      },
      scales: {
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "WEEKS"
            }
          }
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "IN / OUT"
            }
          }
        ]
      },
      legend: false
    }

    return (
      <Container>
        <Line data={data} options={options} ref="chart" />
        <Legend>
          <Value>
            <Color color="#BEA287" />
            <Label>OUT</Label>
          </Value>
          <Value>
            <Color color="#727272" />
            <Label>IN</Label>
          </Value>
        </Legend>
      </Container>
    )
  }
}
