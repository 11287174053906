// ---
// Magma by Secuoyas. 2018.
// Custom theme file.
// ---

export default {
  timming: {
    xs: "0.12s",
    s: "0.25s",
    m: "0.5s",
    l: "1s",
    hover: "0.5s"
  },

  radii: {
    s: "4px",
    m: "8px",
    l: "16px",
    button: "8px"
  },

  line: {
    s: "1px",
    m: "2px",
    l: "4px",
    button: "2px"
  },

  spacing: {
    xxs: "4px",
    xs: "8px",
    s: "16px",
    m: "24px",
    l: "32px",
    xl: "64px",
    xxl: "128px",
    xxxl: "256px",
    zero: "0",
    sideGutter: "6vw",
    button: { minWidth: "100px", padding: "32px", minHeight: "60px" },
    buttonSmall: { minWidth: "100px", padding: "16px", minHeight: "40px" }
  },

  fontWeight: {
    thin: "100",
    light: "400",
    normal: "400",
    bold: "600",
    extraBold: "800"
  }
}
