import React, { Component } from "react"

import styled from "styled-components"

const Content = styled.div`
  ${p => `${p.theme.mq.m} {
      & {
        display: grid;
        grid-template-columns: repeat(${p.cols}, auto);
        grid-gap: ${p.theme.spacing.s};
        justify-items: center;
        }
  }`}
`

export default class Container extends Component {
  render() {
    return <Content cols={this.props.cols}>{this.props.children}</Content>
  }
}
