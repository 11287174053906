import React, { Component } from "react"

import styled from "styled-components"

import DateSelector from "./DateSelector/"
import Container from "./Container"
import GeneralData from "./GeneralData"
import VelocityKPIs from "./VelocityKPIs"
import Graph from "./Graph"
import RefreshData from "./ReloadData"

import DataUtils from "../dataUtils"

const Main = styled.div`
  padding: 1.5rem;
  max-width: 1300px;
  margin: 0 auto;
`

const Error = styled.div`
  color: ${p => p.theme.color.text01};
  background: ${p => p.theme.color.primaryBackground};
  padding: 1.5rem;
  box-shadow: ${p => p.theme.shadow.small};
  position: absolute;
  top: calc(50% - 2.5rem);
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 999;
  height: 5rem;
  width: 20rem;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
`

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1.5rem;
`

const Title = styled.h1`
  ${p => p.theme.textStyle.m};
`

const Section = styled.div`
  margin-bottom: 3rem;
`

const LastUpdate = styled.p`
  flex-grow: 1;
  font-size: 0.7rem;
  margin-left: ${p => p.theme.spacing.s};
  opacity: 0.6;
`

export default class DashboardKanban extends Component {
  constructor(props) {
    super(props)

    this.state = {
      date: {
        start: { month: 1, year: 2018 },
        end: { month: 1, year: 2019 }
      },
      overview: {
        general: { backlog: 0, develop: 0, quality: 0, ready: 0, live: 0 },
        velocityKPIs: { inAvg: 0, througput: 0, timeToEmpty: 0 }
      },
      weeks: [],
      loading: false,
      showError: false,
      rangeDates: {
        start: {
          year: 2018,
          month: 1
        },
        end: {
          year: 2019,
          month: 6
        }
      },
      lastUpdate: "-"
    }
  }

  componentWillMount() {
    this.getData()
  }

  reloadData = () => {
    this.setState({ loading: true }, () => {
      fetch(`${process.env.REACT_APP_SERVER}/updateData`)
        .then(response => {
          return response.json()
        })
        .then(data => {
          data = this.getLastUpdate(data)
          this.setState({ loading: false }, () => {
            this.setData(data)
          })
        })
        .catch(err => {
          this.setState({ showError: true })
        })
    })
  }

  getData = () => {
    fetch(`${process.env.REACT_APP_SERVER}/getData`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        data = this.getLastUpdate(data)
        this.setData(data)
      })
      .catch(err => {
        this.setState({ showError: true })
      })
  }

  getLastUpdate = data => {
    const lastUpdate = data.lastUpdate ? data.lastUpdate : "-"
    delete data.lastUpdate

    this.setState({ lastUpdate })

    return data
  }

  setData = data => {
    const date = DataUtils.getDateRanges(data)
    const weeks = DataUtils.getWeeksArray(date, data)
    const overview = DataUtils.getOverview(weeks)

    this.setState({ data, date, weeks, overview, rangeDates: { ...date } })
  }

  changeDate = data => {
    const weeks = DataUtils.getWeeksArray(data, this.state.data)
    const overview = DataUtils.getOverview(weeks)

    this.setState({ weeks, overview, rangeDates: { ...data } })
  }

  render() {
    return (
      <Main>
        {this.state.showError && <Error>Ocurrió un error al conectarse al servidor</Error>}
        <Section>
          <Heading>
            <Title>Process Overview</Title>
            <LastUpdate>Last update: {this.state.lastUpdate}</LastUpdate>
            <DateSelector date={this.state.date} changeDate={this.changeDate} rangeDates={this.state.rangeDates} />
            <RefreshData reloadData={this.reloadData} loading={this.state.loading} />
          </Heading>

          <GeneralData data={this.state.overview.general} kpis={this.state.overview.velocityKPIs} />
        </Section>

        <Container cols="2">
          <VelocityKPIs data={this.state.overview.velocityKPIs} />
          <Graph data={this.state.weeks} allData={this.state.date} />
        </Container>
      </Main>
    )
  }
}
