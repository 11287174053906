import React, { Component } from "react"
import styled from "styled-components"

import VerticalCard from "./VerticalCard"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const CardGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${p => `${p.theme.mq.m} {
      & {
        display: block
      }
  }`}
`

const Title = styled.h2`
  ${p => p.theme.textStyle.s};
  margin-bottom: 1.5rem;

  ${p => `${p.theme.mq.m} {
      & {
        text-align: center;
      }
  }`}
`

export default class VelocityKPIs extends Component {
  render() {
    return (
      <Container>
        <Title>Velocity KPIs</Title>

        <CardGroup>
          <VerticalCard title="In (AVG)" data={this.props.data.inAvg} label="tasks / week" />
          <VerticalCard title="Througput" data={this.props.data.througput} label="tasks / week" />
          <VerticalCard title="Time to empty" data={this.props.data.timeToEmpty} label="weeks" />
        </CardGroup>
      </Container>
    )
  }
}
