// ---
// Magma by Secuoyas. 2018.
// Magma theme file.
// ---

export default {
  fontFamily: {
    fontFamilies: {
      primary: "'Montserrat', sans-serif",
      secondary: "Work Sans",
      mono: "monospace"
    }
  },
  color: {
    description: "Magma Colors Theme File",
    colors: {
      primaryBackground: {
        description: "Color global de fondo",
        value: "#ffffff"
      },
      interactive01: {
        description: "Color interactivo primario: botones, links, elementos seleccionados, etc..",
        value: "#00338d"
      },
      interactive02: {
        description: "Color interactivo secundario: botón secundario",
        value: "#bea382"
      },
      interactive03: {
        description: "Color interactivo terciario: botón terciario",
        value: "#3D3CF3"
      },
      interactive01Hover: {
        value: "#f5f5f5"
      },
      interactive02Hover: {
        value: "#9e805b"
      },
      ui01: {
        description: "Color primario para fondo de contenedores. Color secundario para fondos de página",
        value: "#F2F4F9"
      },
      ui02: {
        description: "Color primario para fondo de página. Color secundario para fondos de contenedores",
        value: "#ffffff"
      },
      ui03: {
        description: "Borde o separador sutil",
        value: "#acacac63"
      },
      ui04: {
        description: "Borde o separador medio contraste 3:1 AA.",
        value: "#101010"
      },
      ui05: {
        description: "Borde o separador alto contraste 4.5:1 AA.",
        value: "#101010"
      },
      text01: {
        description: "Texto primario. Body, Títulos y hover para text02",
        value: "#6f6f6f"
      },
      text02: {
        description: "Texto secundario. Etiquetas de inputs, texto de ayuda",
        value: "#bea382"
      },
      text03: {
        description: "Texto placeholder",
        value: "#cccccc"
      },
      text04: {
        description: "Texto en elementos interactivos, por ejemplo un botón",
        value: "#ffffff"
      },
      icon01: {
        description: "Iconos primarios",
        value: "#000000"
      },
      icon02: {
        description: "Iconos secundarios",
        value: "#000000"
      },
      icon03: {
        description: "Iconos terciarios. Iconos en elementos interactivos, por ejemplo un bitón.",
        value: "#000000"
      },
      field01: {
        description: "Fondo para campos de formularios",
        value: "#333333"
      },
      field02: {
        description: "Fondo para campos de formularios en fondos ui02",
        value: "#333333"
      },
      inverse01: {
        description: "color inverso para texto e iconos",
        value: "#FFFFFF"
      },
      inverse02: {
        description: "color para fondos de alto contraste",
        value: "#101010"
      },
      support01: {
        value: "#ff0000"
      },
      support02: {
        value: "#18B758"
      },
      support03: {
        value: "#FFD700"
      },
      support04: {
        value: "#3D3CF3"
      },
      overlay: {
        description: "color para los overlays de modales",
        value: "#ffffff",
        opacity: 0.5
      },
      focus: {
        description: "Color para el foco de elementos como botones, formularios, links...",
        value: "#ff0000"
      },
      hoverPrimary: {
        description: "interactive01 hover",
        value: "#00ff00"
      },
      hoverPrimaryText: {
        description: "hover para texto interactivo 01",
        value: "#00ff00"
      },
      hoverSecondary: {
        description: "interactive02 hover",
        value: "#ff0000"
      },
      hoverSecondaryText: {
        description: "hover para texto interactivo 02",
        value: "#00ff00"
      },
      hoverTerciary: {
        description: "interactive03, interactive03 text hover",
        value: "#ff0000"
      }
    }
  },

  mediaquery: {
    description: "",
    mediaqueries: [
      {
        label: "default",
        minWidth: null
      },
      {
        label: "s",
        minWidth: "480px"
      },
      {
        label: "m",
        minWidth: "720px"
      },
      {
        label: "l",
        minWidth: "960px"
      },
      {
        label: "xl",
        minWidth: "1152px"
      },
      {
        label: "xxl",
        minWidth: "1536px"
      },
      {
        label: "xxxl",
        minWidth: "1680px"
      },
      {
        label: "onlyxl",
        minWidth: "112em",
        maxWidth: "124em"
      }
    ]
  },

  textStyle: {
    defaultStyles: {
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: "0px",
      fontSize: "10px"
    },
    textStyles: [
      {
        name: "cypher",
        fontSize: "60px",
        letterSpacing: "-1px",
        responsive: [
          {
            breakpoint: "l",
            fontSize: "90px",
            letterSpacing: "-1px"
          }
        ]
      },
      {
        name: "mega",
        fontSize: "60px",
        letterSpacing: "0px",
        responsive: [
          {
            breakpoint: "m",
            fontStyle: "normal",
            fontSize: "80px"
          }
        ]
      },
      {
        name: "xxl",
        fontSize: "64px",
        lineHeight: 1.2,
        responsive: [
          {
            breakpoint: "m",
            fontSize: "64px"
          }
        ]
      },
      {
        name: "xl",
        fontSize: "52px",
        lineHeight: 1.2,
        responsive: [
          {
            breakpoint: "m",
            fontSize: "52px"
          }
        ]
      },
      {
        name: "l",
        fontSize: "41px",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "41px"
          }
        ]
      },
      {
        name: "m",
        fontSize: "33px",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "33px"
          }
        ]
      },
      {
        name: "s",
        fontSize: "20px",
        responsive: [
          {
            breakpoint: "m",
            fontSize: "24px"
          }
        ]
      },
      {
        name: "xs",
        fontSize: "21px",
        lineHeight: 1.7,
        responsive: [
          {
            breakpoint: "m",
            fontSize: "21px"
          }
        ]
      },
      {
        name: "body",
        fontSize: "16px",
        lineHeight: 1.4
      },
      {
        name: "tiny",
        fontSize: "14px"
      },
      {
        name: "button",
        fontSize: "14px",
        fontWeight: "800"
      },
      {
        name: "buttonSmall",
        fontSize: "11px",
        fontWeight: "800"
      },
      {
        name: "foo",
        fontSize: "111px",
        fontWeight: "800"
      }
    ]
  },
  gradient: {
    description: "",
    gradients: {
      spectrum: {
        direction: "60deg",
        stops: [
          {
            color: "#ff0000",
            opacity: 1.0,
            position: "0%"
          },
          {
            color: "#ff00ff",
            opacity: 1.0,
            position: "15%"
          },
          {
            color: "#0000ff",
            opacity: 1.0,
            position: "33%"
          },
          {
            color: "#00ffff",
            opacity: 1.0,
            position: "49%"
          },
          {
            color: "#00ff00",
            opacity: 1.0,
            position: "67%"
          },
          {
            color: "#ffff00",
            opacity: 1.0,
            position: "84%"
          },
          {
            color: "#0000ff",
            opacity: 1.0,
            position: "100%"
          }
        ]
      },
      gradient01: {
        direction: "to top",
        stops: [
          {
            color: "#443CF3",
            opacity: 1.0,
            position: "0%"
          },
          {
            color: "#425FF7",
            opacity: 1.0,
            position: "100%"
          }
        ]
      }
    }
  },

  shadow: {
    description: "",
    shadows: {
      small: {
        x: 0,
        y: 2,
        blur: 2,
        color: "#000000",
        opacity: 0.14
      },
      big: {
        x: 0,
        y: 25,
        blur: 128,
        color: "#000000",
        opacity: 0.4
      }
    }
  }
}
