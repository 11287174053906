import React from "react"
// import logo from "./logo.svg"
import "./App.css"

import defaultTheme from "./themes/default"

import { createGlobalStyle, ThemeProvider } from "styled-components"
import DashboardKanban from "./components/DashboardKanban"
import Nav from "./components/Nav"

require("dotenv").config()

function App() {
  const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Montserrat');

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  
  body {
    background: ${p => p.theme.color.ui01};
    font-family: ${p => p.theme.fontFamily.primary};
    color: ${p => p.theme.color.text01};
  }

  button:focus {outline:0;}

`
  return (
    <ThemeProvider theme={defaultTheme}>
      <>
        <GlobalStyle />
        <Nav />
        <DashboardKanban />
      </>
    </ThemeProvider>
  )
}

export default App
