export default {
  getDateOfWeek(w, y) {
    // Returns month
    var d = 1 + (w - 1) * 7 // 1st of January + 7 days for each week

    return (new Date(y, 0, d).getMonth() + 1).toString()
  },

  getDateRanges(date) {
    const minYear = Object.keys(date)[0]
    const minMonth = this.getDateOfWeek(Object.keys(date[minYear])[0], minYear)

    const maxYear = Object.keys(date)[Object.keys(date).length - 1]
    const maxMonth = this.getDateOfWeek(Object.keys(date[maxYear])[Object.keys(date[maxYear]).length - 1], maxYear)

    return {
      start: { month: +minMonth, year: +minYear },
      end: { month: +maxMonth, year: +maxYear }
    }
  },

  // Get two dates ({year, month}) and returns an array of weeks
  getWeeksArray({ start, end }, data) {
    const weeks = {}

    for (let i = start.year; i <= end.year; i++) {
      const dataYear = data[i]
      weeks[i] = {}
      Object.keys(dataYear).forEach(week => {
        const weekMonth = +this.getDateOfWeek(week, i)

        if (
          (i === start.year && i === end.year && weekMonth >= start.month && weekMonth <= end.month) ||
          (i === start.year && weekMonth >= start.month && i !== end.year) ||
          (i === end.year && weekMonth <= end.month && i !== start.year) ||
          (i > start.year && i < end.year)
        ) {
          weeks[i][week] = data[i][week]
        }
      })
    }
    return weeks
  },

  getOverview({ ...weeks }) {
    const totalWeeks = []

    // Join weeks
    Object.keys(weeks).forEach(year => {
      Object.keys(weeks[year]).forEach(week => {
        totalWeeks.push(weeks[year][week])
      })
    })

    // General
    const backlog = totalWeeks.reduce((acc, act) => acc + act.waiting + act.ready, 0)
    const develop = totalWeeks.reduce((acc, act) => acc + act.inProgress, 0)
    const quality = totalWeeks.reduce((acc, act) => acc + act.qa, 0)
    const ready = totalWeeks.reduce((acc, act) => acc + act.push, 0)
    const live = totalWeeks.reduce((acc, act) => acc + act.in, 0)

    // VelocityKPIs Math.round( number * 10 ) / 10
    const inAvg = this.roundToOneDec(totalWeeks.reduce((acc, act) => acc + act.in, 0) / totalWeeks.length)
    const througput = this.roundToOneDec(totalWeeks.reduce((acc, act) => acc + act.out, 0) / totalWeeks.length)
    const timeToEmpty = this.roundToOneDec(backlog / througput)

    return {
      general: { backlog, develop, quality, ready, live },
      velocityKPIs: { inAvg, througput, timeToEmpty }
    }
  },

  roundToOneDec: number => Math.round(number * 10) / 10
}
